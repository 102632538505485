.profile-card {
  margin-bottom: 30px;
}

.login-under-title {
  color: gray;
  margin-bottom: 30px;
}

.set-link-form {
  display: flex;
  align-items: center;
  row-gap: 5px;
  flex-wrap: wrap;
  margin: 15px 0;

  p {
    color: gray;
    margin-right: 10px;
  }
}

.set-link-form-inner,
.profile-input-btn {
  display: flex;
  align-items: stretch;

  input {
    border-radius: 5px 0 0 5px;
  }

  button {
    border-radius: 0 5px 5px 0;
  }
}

.profile-input-btn {
  margin: 5px 0 15px;
}

.profile-link,
.profile-link-error,
.email-wrap,
.activation-wrap {
  width: 600px;
  max-width: 100%;
}

.profile-link-error p {
  padding-bottom: 15px;
}

.link-wrap {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 15px;
  align-items: stretch;
  margin: 5px 0 15px;
}

.link-url {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid;
  padding: 0 8px;
  border-color: #D9EACA #C3DAAC #AAC68E #c2dca9;
  color: #719a47;
  background: -moz-linear-gradient(top, #f0f5eb 0%, #dfe5d8 100%);
  background: -webkit-linear-gradient(top, #f0f5eb 0%, #dfe5d8 100%);
  background: linear-gradient(to bottom, #f0f5eb 0%, #dfe5d8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0f5eb', endColorstr='#dfe5d8', GradientType=0);
  cursor: pointer;

  span {
    font-weight: bold;
  }
}

.email-wrap {
  a {
    font-weight: bold;
  }
}

.your-email {
  margin-bottom: 10px;

  span {
    color: #719a47;
    font-weight: bold;
  }
}

.activation-wrap p {
  margin-bottom: 10px;
}

.pass-line {
  margin-bottom: 15px;

  p {
    margin-bottom: 0;
  }
}

@media (max-width: 600px) {

  .profile-page .loading-wrap {
    width: 100%;
  }

  .profile-link,
  .profile-link-error {
    max-width: 100%;
    width: 100%;
  }

  .set-link-form-inner input,
  .profile-input-btn input {
    max-width: 200px;
    width: 100%;
  }
}

@media (max-width: 350px) {
  .set-link-form-inner input {
    max-width: 160px;
  }
}