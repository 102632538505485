.content .payment-page {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  h1,
  > p {
    width: 100%;
  }

  h1 {
    margin-bottom: 0;
  }
}

.pay-wrap {
  width: 100%;
  max-width: 280px;
  text-align: center;

  p {

    &:nth-of-type(1) {
      font-size: 1.4rem;
      line-height: 2rem;
    }

    span {
      color: #4646f9;
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  .btn {
    margin-top: 15px;
  }
}

@media (max-width: 500px) {
  .pay-wrap {
    max-width: 100%;
  }
}