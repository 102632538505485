header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 16%), 0 0 4px 0 rgb(0 0 0 / 16%);
}

.h-logo {
  a {
    display: flex;
    padding: 1rem;

    span {
      display: flex;
    }

    .h-logo-title {
      font-size: 2rem;
      line-height: 2rem;
      text-transform: uppercase;
      font-weight: bold;
      color: #6a237e;
    }

    .h-logo-sub {
      color: yellow;
      font-size: 1rem;
      text-shadow: 1px 0 2px rgb(0 0 0);
      align-items: flex-end;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.h-auth {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 0.5rem;
}

.h-user-web a,
.landing-h-user-web {
  display: block;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 0.5rem;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid transparent;

  &:hover {
    text-decoration: none;
    background: #f5f5f5;
    color: #040404;
    border-radius: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.h-user-mob,
.landing-h-user-mob {
  display: none;
}

.h-user-mob a,
.landing-h-user-mob {
  padding: 8px;
  border: 1px solid #6a237e;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 16%), 0 0 4px 0 rgb(0 0 0 / 16%);
  display: flex;

  svg {
    color: #6a237e;
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}

.landing-h-user-mob {
  display: none;
}

.burger-wrap {
  display: none;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-left: 1rem;
}

.burger {
  width: 40px;
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    width: 30px;
    height: 3px;
    background-color: #6a237e;
    display: inline-block;
    margin-bottom: 8px;
    transition: all 0.2s ease-out;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1023px) {

  .h-user-web,
  .landing-h-user-web {
    display: none;
  }

  .h-user-mob {
    display: block;
  }

  .landing-h-user-mob,
  .burger-wrap {
    display: flex;
  }

}