.loading-dashboard {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.intro {
  padding-bottom: 5rem;
  background: rgb(219, 204, 255);
  background: linear-gradient(145deg, rgba(219, 204, 255, 1) 0%, rgba(143, 204, 254, 1) 100%);

  .inner {
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
  }

  h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
    padding-top: 4rem;
    padding-bottom: 1rem;
    font-weight: bold;
  }

  .subtitle {
    font-size: 1.8rem;
    line-height: 2.2rem;
    max-width: 600px;
    margin: auto;
    padding: 2rem 1rem;
  }

  .intro-btns {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    a {
      text-decoration: none;
    }
  }
}

.steps {
  padding: 1rem 1rem 3rem;

  .inner {
    max-width: 600px;
    margin: auto;
  }

  .steps-title {
    font-size: 2rem;
    line-height: 2.2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-weight: bold;
    text-align: center;

    span {
      color: #4646f9;
      font-size: 2.4rem;
    }
  }

  .steps-single {
    display: flex;
    align-items: center;
    padding-bottom: 1.5rem;
    gap: 1rem;

    p {
      font-size: 1.4rem;
      line-height: 1.8rem;

      span {
        color: #4646f9;
        font-weight: bold;
      }
    }
  }

  .steps-number {
    color: #4646f9;
    font-size: 3rem;
    border: 1px solid #4646f9;
    line-height: 3rem;
    height: 4rem;
    display: flex;
    width: 4rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffffff;
    font-weight: bold;
  }
}

.you-receive {
  padding: 1rem 1rem 3rem;
  background: rgb(219, 204, 255);
  background: linear-gradient(325deg, rgba(219, 204, 255, 1) 0%, rgba(143, 204, 254, 1) 100%);

  .inner {
    text-align: center;
    max-width: 600px;
    margin: auto;
  }

  .you-receive-title {
    font-size: 2.4rem;
    line-height: 2.8rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    font-weight: bold;
  }

  .you-receive-single {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 1rem;
    padding-bottom: 1.5rem;

    p {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    span {
      color: #4646f9;
      font-size: 2.4rem;
      border: 1px solid #4646f9;
      line-height: 3rem;
      height: 4rem;
      display: flex;
      width: 4rem;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #ffffff;
      font-weight: bold;
    }
  }

}

.price {
  padding: 1rem 1rem 3rem;

  .inner {
    max-width: 600px;
    margin: auto;
  }

  .price-title {
    font-size: 2rem;
    line-height: 2.2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-weight: bold;
    text-align: center;
  }

  .price-line {
    font-size: 1.4rem;
    line-height: 1.8rem;

    span {
      font-size: 2rem;
      line-height: 2.2rem;
      color: #4646f9;
    }
  }

  .price-note {
    color: rgba(0, 0, 0, 0.54);
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;

    span {
      font-size: 2rem;
      color: #4646f9;
    }
  }

  .price-try {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 480px) {

  .intro {
    padding-bottom: 3rem;

    h1 {
      font-size: 2rem;
      line-height: 2.4rem;
      padding-top: 2rem;
    }

    .subtitle {
      padding: 1.5rem 0;
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }

  .steps {
    padding-bottom: 1rem;

    .steps-title {
      font-size: 1.6rem;
      line-height: 2rem;
      padding: 1rem 0;

      span {
        font-size: 2rem;
      }
    }

    .steps-single p {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  .you-receive {
    padding-bottom: 1rem;

    .you-receive-title {
      font-size: 1.6rem;
      line-height: 2rem;
      padding: 1rem 0;
    }

    .you-receive-single p {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  .price {
    padding-bottom: 2rem;

    .price-title {
      font-size: 1.6rem;
      line-height: 2rem;
      padding: 1rem 0;
    }

    .price-line,
    .price-try {
      font-size: 1.2rem;
      line-height: 1.6rem;

      span {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }

    .price-note {
      font-size: 0.8rem;
      line-height: 1rem;
    }
  }

}