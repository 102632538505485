.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 0.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1.2rem;
  line-height: 1.4rem;
  border: 1px solid transparent;
  cursor: pointer;
  font-family: 'Noto Sans', sans-serif;

  svg {
    margin-left: 5px;
  }

  &.icon {
    color: #040404;
    border: 1px solid #d1d1d1;
    background: #f0f0f0;

    svg {
      margin: 0;
    }

    &:hover {
      background: #e3e3e3;
    }

    &.active {
      background-color: #4646f9;
      border: 1px solid #4646f9;
      color: #ffffff;
    }
  }

  &.btn-blue {
    background-color: #4646f9;

    &:hover {
      background-color: #2828f9;
    }
  }

  &.btn-violet {
    background-color: #A967D5;

    &:hover {
      background-color: #993dd7;
    }
  }

  &.btn-red {
    background-color: #f94646;

    &:hover {
      background-color: #db2e2e;
    }
  }

  &.btn-orange {
    background-color: orange;

    &:hover {
      background-color: #ef9900;
    }
  }

  &:disabled {
    cursor: no-drop;
    background: #b3b3b3;

    &:hover {
      background-color: #b3b3b3;
    }
  }

  &.full {
    width: 100%;
  }

  &.btn-el-add {
    background: #ffffff;
    color: #4646f9;
    border: 1px solid #4646f9;

    &:hover {
      color: #ffffff;
      background-color: #2828f9;
    }
  }
}