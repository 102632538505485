.dash-body {
  min-height: calc(100vh - 65px);
  display: flex;
  justify-content: space-between;
}

.sidebar {
  display: block;
  background: #ffffff;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 16%), 0 0 4px 0 rgb(0 0 0 / 16%);
  width: 220px;
  z-index: 300;

  ul li {
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);

    a {
      display: block;
      padding: 8px 15px;
      color: #040404;

      &:hover, &.active {
        text-decoration: none;
        background: #7676ff;
        color: #ffffff;
      }
    }

  }
}

.logout {
  padding: 15px;
  text-align: center;
}

.content {
  flex-grow: 1;
  padding: 15px;
  width: calc(100% - 220px);

  h1 {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 15px;

    &.no-margin {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1023px) {
  .dash-body {
    min-height: initial;
    position: relative;
  }

  .content {
    width: 100%;
  }

  .sidebar {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    width: 100%;
    height: calc(100vh - 65px);
    box-shadow: none;
    border: none;
    display: none;

    &.active {
      display: block;
    }

    .inner {
      width: 220px;
      position: absolute;
      background: #ffffff;
      top: 0;
      right: 0;
      border-radius: 0 0 0 8px;
    }
  }

  .sidebar-overflow {
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
  }
}