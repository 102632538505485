.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  z-index: 600;
  border-radius: 4px;
  background: #ffffff;
  width: 450px;
  max-width: calc(100vw - 2rem);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 16%), 0 0 4px 0 rgb(0 0 0 / 16%);
}

.modal-header {
  padding: 10px 40px 10px 15px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  p {
    font-size: 1.4rem;
    word-break: break-word;
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    cursor: pointer;

    &:before, &:after {
      content: "";
      display: block;
      width: 25px;
      height: 3px;
      background: #6a237e;
      border-radius: 4px;
      top: 50%;
      margin-top: -2px;
      position: absolute;
      left: 50%;
      margin-left: -13px;
      transform: rotate(45deg);
    }
    &:before {
      transform: rotate(135deg);
    }
  }
}

.modal-body {
  padding: 15px 15px 30px;
}



.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  background: rgba(0,0,0,0.3);
}