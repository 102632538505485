.input, .textarea {
  outline: none;
  border: 1px solid #dddfe2;
  color: #1d2129;
  font-size: 16px;
  line-height: 30px;
  border-radius: 5px;
  padding: 0 10px;
  box-shadow: rgb(204, 219, 232) 0 1px 3px 0 inset;

  &.input-error {
    border: 1px solid red;
  }
}

.textarea {
  resize: vertical;
}