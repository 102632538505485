.form-send-message-wrap {
  width: 100%;

  .form-submit {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 360px) {
  .form-send-message-wrap input {
    max-width: calc(100% - 80px);
  }
}