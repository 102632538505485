.editor-wrap {
  display: flex;
}

.editor-main {
  width: calc(100% - 400px - 30px);
  margin-right: 30px;
}

.e-preview {
  background: url("../../images/phone.png") no-repeat top center / cover;
  width: 400px;
  height: 786px;
  overflow: hidden;
  position: relative;

  .ep-inner-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
    margin: 30px 32px;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .ep-loading-wrap {
      height: calc(100% - 28px) !important;
      top: 28px;
    }
  }
}

.ep-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 0;
  width: calc(100% + 17px) !important;
  height: 100%;
  overflow-y: scroll;
}

.em-new-part {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 15px;
  flex-wrap: wrap;
  border: 2px dashed #7676ff;
  text-align: center;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
  margin-bottom: 15px;

  &:hover {
    background: #f5f5f5;
  }
}

.module-wrap {
  border: 1px solid #7676ff;
  padding: 15px;
  border-radius: 10px;
  background: #e2ebff;
  width: 100%;

  textarea {
    padding: 5px;
    min-height: 90px;
  }
}

.module-text {
  display: flex;
  align-items: flex-start;
  gap: 15px;

  label {
    margin-bottom: 0;
  }

  select {
    outline: none;
    border: 1px solid #dddfe2;
    color: #1d2129;
    font-size: 16px;
    line-height: 30px;
    border-radius: 5px;
    padding: 5px 10px;
    box-shadow: rgb(204 219 232) 0 1px 3px 0 inset;
  }

  .module-panel {
    width: 50%;
  }
}

.module-panel {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.m-group {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.phone-bar {
  width: 100%;
  position: absolute;
  top: 0;
  height: 28px;
  z-index: -1;
  background: #323232;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}

.part-wrap {
  padding: 0 15px;
  margin-bottom: 15px;
  width: 100%;
}

.editor-parts-wrap {
  width: 100%;
}

.editor-single-part {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  flex-wrap: wrap;
  border: 1px solid #7676ff;
  border-radius: 10px;
  margin-bottom: 5px;
}

.esp-icon {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 10px 0 0 10px;
  background: #f5f5f5;
  color: #4646f9;
  border-right: 1px solid #4646f9;
}

.esp-control {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
  background: #f5f5f5;
  border-radius: 0 10px 10px 0;
  border-left: 1px solid #4646f9;
}

.esp-order {
  display: flex;
  flex-direction: column;
}

.esp-order-up,
.esp-order-down {
  background: #ffffff;
  border: 1px solid #4646f9;
  color: #4646f9;
  display: flex;
  padding: 5px;
  cursor: pointer;

  &:hover {
    color: #ffffff;
    background: #4646f9;
  }
}

.esp-order-up {
  border-radius: 5px 5px 0 0;
  border-bottom: none;

  &.esp-order-up-bordered {
    border-bottom: 1px solid #4646f9;
  }
}

.esp-order-down {
  border-radius: 0 0 5px 5px;
}

.esp-delete {
  display: flex;
  padding: 5px;
  border: 1px solid #f94646;
  border-radius: 5px;
  color: #f94646;
  background: #ffffff;
  cursor: pointer;

  &:hover {
    background: #f94646;
    color: #ffffff;
  }
}