.auth-login {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 15px;
}

.auth-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 15px;
}

@media (max-width: 360px) {
  .auth-wrap .input {
    max-width: calc(100% - 80px);
  }
}