.label {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 15px;

  span {
    width: 80px;
  }

  .input, .textarea {
    flex-grow: 1;
  }
}

.form-send-message-inline .label {
  span {
    width: 110px;
  }
}

@media (max-width: 480px) {
  .form-send-message-inline .label {
    flex-wrap: wrap;
    gap: 5px;
  }
}