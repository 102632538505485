.error-line {
  color: red;

  &.center {
    text-align: center;
  }

  &.p15 {
    padding: 15px;
  }
}