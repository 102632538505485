footer {
  background: #e3e3e3;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.f-left {
  p {
    margin-bottom: 0.5rem;
  }

  .btn {
    margin-bottom: 1rem;
  }
}

.copyright {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 0;

  img {
    width: 20px;
    margin-left: 0.5rem;
    position: relative;
    top: 1px;
  }
}

.f-right {
  li {
    margin-bottom: 0.5rem;
  }

  a {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.54);
  }
}