.loading {
  background: #f3f3f3;
  text-align: center;
  border-radius: 10px;
  padding: 15px;
  animation-name: preloaderPulse;
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease;
}

.loading-wrap {
  position: relative;
  display: inline-flex;

  .loading {
    position: relative;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(243 243 243 / 65%);
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: preloaderPulseTransparent;
  }

  &.fullwidth {
    width: 100%;
    height: 100%;
  }

  &.loading-wrap-already .loading {
    position: absolute;
  }

  &.loading-wrap-small {
    width: 75px;
    height: 75px;
  }

  &.loading-wrap-medium {
    width: 125px;
    height: 125px;
  }

  &.loading-wrap-large {
    width: 175px;
    height: 175px;
  }

  &.loading-error {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  &.align-center {
    align-items: center;
  }
}

@keyframes preloaderPulse {
  to {
    background-color: #e0eeff;
  }
}

@keyframes preloaderPulseTransparent {
  to {
    background: rgb(224 238 255 / 50%);
  }
}