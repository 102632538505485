* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  line-height: 150%;
  font-family: 'Noto Sans', sans-serif;
}

body {
  background: #f5f5f5;
}

.red {
  color: red;
}

.green {
  color: #719a47;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.empty {
  flex-grow: 1;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #719a47;

  &:hover {
    text-decoration: underline;
  }
}

.mb5 {
  margin-bottom: 5px;
}

.mb15 {
  margin-bottom: 15px;
}

.text-sub {
  color: gray;
  font-size: 0.8rem;
  line-height: 1rem;
}

.content-wrap {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 16%), 0 0 4px 0 rgb(0 0 0 / 16%);
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  gap: 15px;
}

.weight {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.upper {
  text-transform: uppercase;
}

.size-1,
.size-2,
.size-3,
.size-4,
.size-5 {
  line-height: 150%;
}

.size-1 {
  font-size: 10px;
}

.size-2 {
  font-size: 13px;
}

.size-3 {
  font-size: 16px;
}

.size-4 {
  font-size: 24px;
}

.size-5 {
  font-size: 32px;
}